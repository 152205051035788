import { useEffect, useState } from "react";
import axios from "axios"
import qs from "qs"

import { getAccessToken, getRefreshToken } from "../../token/UserToken";
import VanillaWrapper from "../VanillaWrapper/VanillaWrapper";
import { client_id, client_secret } from "../../util/env";


export default function Epedidos() {
  const [token, setToken] = useState(getAccessToken()||"")
  // let token:string=""

  useEffect(() => {
    // setToken(getAccessToken() || "")
    // setInterval( ()=>refreshToken(), 58*60000)
    const interval = setInterval( ()=>refreshToken(), 30*60000)
    return ()=>clearInterval(interval)

  }, [])


  async function refreshToken() {
    // console.log("refreshToken", getRefreshToken())
    let data = qs.stringify({
      'client_secret': client_secret,
      'client_id': client_id,
      'refresh_token': getRefreshToken(),
      'grant_type': "refresh_token",
    });

    let config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: 'https://auth.cosmospro.com.br/BZP/connect/token',
      headers: {
        
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };
    // console.log("config", config)
    await axios.request(config)
    .then((response) => {
        // console.log("response refresh", response)
        setToken(response.data.access_token)
        // console.log("JSON.stringify(response.data)", response.data);
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
      <VanillaWrapper token={token} />
    // <div style={{ position: 'relative', width: '100%', height: '100%'}}>
    //   <iframe src="/011/project.e.web.order.html" style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', }} title="Vanilla JS website" />
    // </div>
  )
}