// import styles from './index.module.css';
import { Button, TextField } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import axios from "axios"
import qs from "qs"

import { client_id, client_secret, grant_type, notifyChange, scope } from "../../util/env"
import { getUserToken } from "../../token/UserToken"
import { LoadPanel } from "devextreme-react"
import './style.css'



export default function Login() {
    const [loadPanelVisible, setLoadPanelVisible] = useState(false)
    // const [userName, setUserName] = useState("procfit.jb@procfit.com.br")
    const [userName, setUserName] = useState("")
    // const [userPassword, setUserPassword] = useState("Co$Wo$#@dm1n?D3v")
    const [userPassword, setUserPassword] = useState("")
    const navigate = useNavigate()

    function onchangeUserName(e: any) {
        setUserName(e.target.value)
    }
    function onchangeUserPassword(e: any) {
        setUserPassword(e.target.value)
    }

    function login(e: any) {
        setLoadPanelVisible(true)
        e.preventDefault()
        let data = qs.stringify({
            'grant_type': grant_type,
            'username': userName,
            'password': userPassword,
            'scope': scope,
            'client_id': client_id,
            'client_secret': client_secret
        });
        let config = {
            method: 'post',
            // maxBodyLength: Infinity,
            url: 'https://auth.cosmospro.com.br/BZP/connect/token',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios.request(config)
            .then((response: any) => {
                console.log("login", response);
                localStorage.setItem("access_token", response.data.access_token);
                localStorage.setItem("refresh_token", response.data.refresh_token);
                localStorage.setItem("user-id", getUserToken(response.data.access_token).sub);
                navigate("epedidos", {
                    relative:'route'
                });
            })
            .catch((error: any) => {
                if (error.response?.data?.error === "invalid_grant")
                    notifyChange("Usuário ou senha invalido", "error")
                else
                    notifyChange("Não foi possivel realizar o acesso", "error")
                console.log("error", error);
            })
            .finally(() => setLoadPanelVisible(false));
    }
    return (
        // <div className={styles.view}>
        <div className='view'>
            <img src="./img/logo-nazaria-white.png" />
            <section className='content'>
                <article className='content-img'>
                    {/* <img src="./img/armazem2-scaled.jpg" /> */}
                    <img src="https://newapi.cosmospro.com.br/api/PublicFileManager/DownloadFormFile(tenantKey=BZP,fieldId=115444,recordId=1)" />
                </article >

                <form onSubmit={login} className='content-form'>
                    <div className='tag'>
                        <h2>Bem Vindo</h2>

                    </div>
                    <div className="content-form-login">

                        <h3>Acesse sua conta</h3>
                        <div className='field'>
                            <TextField
                                required
                                id="standard-required"
                                label="Usuário"
                                variant="standard"
                                className="field-input"
                                onChange={onchangeUserName}
                                value={userName}
                            />

                        </div>
                        <div className='field'>
                            <TextField
                                required
                                id="standard-password-input"
                                label="Senha"
                                type="password"
                                autoComplete="current-password"
                                variant="standard"
                                className="field-input"
                                onChange={onchangeUserPassword}
                                value={userPassword}
                            />

                        </div>
                        <div className='field'>
                            <Button variant="contained" type="submit">Login</Button>
                        </div>

                        {/* <div className='field'>
                            <span>Esqueci minha senha</span>
                        </div> */}
                    </div>
                </form>

            </section>
            <footer className="rodape">
                <p>Copyright 2022 - Nazária Distribuidora Farmacêutica | Todos os direitos reservados </p>
                <a href="https://cosmospro.com.br/" target="_blank">
                    <div className="content-copy">
                        <span className="text-copy">Desenvolvido por </span>
                        <img className="logo-rodape" src="https://bibliotecas.cosmospro.com.br/cosmos/e-pedidos-web/nazaria/v2/img/logotipo-cosmospro-inverse-200px.png" alt="logo" />
                    </div>
                </a>
            </footer>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.1)"
                visible={loadPanelVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
        </div>

    )
}